
<template>
  <div>
    <div class="buy_ticket_pc">
      <div class="ticket_title">
        <h2>选择门票</h2>
        <p class="title">CHOOSE TICKETS</p>
      </div>
      <div class="ticket_list">
        <table>
          <tr class="title_row">
            <td class="column1">{{ isSpecial ? '门票种类' : '门票名称' }}</td>
            <td class="column2">优惠单价/原价</td>
            <td class="column3">说明</td>
            <td class="column4">数量</td>
          </tr>
          <tr :class="`content_row ${selectedTicket.id === item.id ? 'active' : ''} ${isSpecial ? 'special' : ''}`" v-for="(item, index) in (isSpecial ? diysList : ticketList)" :key="'buy_ticket_pc_'+index">
            <td class="column1">{{ isSpecial ? item.goodsName : item.title }}</td>
            <td class="column2">
              ¥ {{ item.price }} &nbsp;/&nbsp;
              <span class="old_price">¥ {{ item.originalPrice }}</span>（元 / 张）
            </td>
            <td class="column3">{{ item.description }}</td>
            <td class="column4">
              <button class="btn reduce" @click="reduce(item)">-</button>
              <input class="amount" v-model="item.num" maxlength="2" minlength="1" disabled />
              <button class="btn add" @click="add(item)">+</button>
            </td>
          </tr>
        </table>
      </div>
      <div class="operation_list" :class="[isSpecial?'special':'', 'flex-con']">
        <div class="user_info" v-if="isSpecial">
          <div class="info_title">
            {{ diysList[0].title || '购票人信息' }}
            <p>由 <a href="https://www.msup.com.cn/" target="_blank">
              <img src="https://www.msup.com.cn/Public/Home2018/images/logo_white.png" />
            </a> 提供票务支持</p>
          </div>
          <div class="info_option info_name">
            <label>姓名：</label>
            <input type="text" v-model="userInfo.name">
          </div>
          <div class="info_option info_company">
            <label>公司：</label>
            <input type="text" v-model="userInfo.company">
          </div>
          <div class="info_option info_email">
            <label>邮箱：</label>
            <input type="text" v-model="userInfo.email">
          </div>
          <div class="info_option info_phone">
            <label>手机号：</label>
            <input type="text" v-model="userInfo.phone">
          </div>
          <div class="info_option info_code">
            <label>验证码：</label>
            <input type="text" v-model="userInfo.code" autocomplete="off">
            <a @click="sendCode">{{ codeTips }}{{ codeCountDown>0 ? `(${codeCountDown<10?'0':''}${codeCountDown}s)` : '' }}</a>
          </div>
        </div>
        <div class="total">
          <p>总计</p>
          <h2>¥ {{ totalAmount }}</h2>
          <div class="pay_wrapper">
            <span>提示：将跳转到 msup 统一订单结算中心</span>
            <button class="pay" @click="generateOrder">立即支付</button>
          </div>
        </div>
      </div>
    </div>
    <div class="buy_ticket_mob">
      <div class="ticket_title">
        <h2>选择门票</h2>
        <p class="title">CHOOSE TICKETS</p>
      </div>
      <div :class="`ticket_item ${selectedTicket.id === item.id ? 'active' : ''}`" v-for="(item, index) in (isSpecial ? diysList : ticketList)" :key="'buy_ticket_mob_'+index">
        <div class="top">
          <div class="left">
            <div>
              <img src="https://f.msup.com.cn/order02.png" v-show="selectedTicket.id !== item.id" />
              <img src="https://f.msup.com.cn/order01.png" v-show="selectedTicket.id === item.id" />
            </div>
            <p>{{ isSpecial ? item.goodsName : item.title }}</p>
          </div>
          <div class="right">
            <button class="btn add_btn" v-on:click="reduce(item)" v-bind:disabled="item.num === 0">-</button>
            <input class="amount" v-model="item.num" disabled />
            <button class="btn reduce_btn" v-on:click="add(item)">+</button>
          </div>
        </div>
        <div class="bottom">
          <div class="price">
            <p>￥</p>
            <h2>{{ item.price }}</h2>
            <span>（元 / 张）</span>
          </div>
          <h4>￥{{ item.originalPrice }}</h4>
          <h3>{{ item.description }}</h3>
        </div>
      </div>
      <div class="user_info ticket_item active" v-if="isSpecial">
        <div class="top">
          <div class="left">
            <!-- <p>{{ diysList[0].title || '购票人信息' }}</p> -->
            <h3>
              {{ diysList[0].title || '购票人信息' }}
              <p>由 <a href="https://www.msup.com.cn/" target="_blank">
                <img src="https://www.msup.com.cn/Public/Home2018/images/logo_black.png" />
              </a> 提供票务支持</p>
            </h3>
          </div>
        </div>
        <div class="info_option info_name">
          <label>姓名：</label>
          <input type="text" v-model="userInfo.name">
        </div>
        <div class="info_option info_company">
          <label>公司：</label>
          <input type="text" v-model="userInfo.company">
        </div>
        <div class="info_option info_email">
          <label>邮箱：</label>
          <input type="text" v-model="userInfo.email">
        </div>
        <div class="info_option info_phone">
          <label>手机号：</label>
          <input type="text" v-model="userInfo.phone">
        </div>
        <div class="info_option info_code">
          <label>验证码：</label>
          <input type="text" v-model="userInfo.code" autocomplete="off">
          <a @click="sendCode">{{ codeTips }}{{ codeCountDown>0 ? `(${codeCountDown<10?'0':''}${codeCountDown}s)` : '' }}</a>
        </div>
      </div>
      <div class="total">
        <button class="pay_btn" v-on:click="generateOrder">立即支付</button>
        <p class="total_price">
          总计：<span>￥{{ totalAmount }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { sid, getTicket, getDiys, createOrder, payStands, createOrderGoods, sendCaptcha, getUserInfo } from "@/utils/api.js";

export default {
  props: {
    hash: [String], // 有效渠道码->获取对应的优惠票种 // 暂不启用
    qd: [String], // 渠道码->仅作为标记不一定有对应的优惠票
    // userInfo: [Object], // 用户(购票人)信息
  },
  data() {
    return {
      // qd: "",
      diysList: [],
      ticketList: [],
      totalAmount: 0,
      selectedTicket: {},
      userInfo: { name:'', company:'', email:'', phone:'', code:'' },
      codeTips: '发送验证码',
      codeCountDown: 0,
    };
  },
  computed: {
    isSpecial() {
      let flag = this.qd && this.qd.length>0 && this.diysList && this.diysList.length>0;
      return flag;
    },
  },
  watch: {
    hash: {
      handler(_new, _old) {
        // console.log(_new, '|', _old);
        if(_new && _new.length>0) {
          this.getTicketListDiys(_new);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    // const { qd } = this.$route.query;
    // this.qd = qd !== undefined ? qd : "";
    this.getTicketList();
  },
  methods: {
    getTicketListDiys(_hash) {
      this.diysList = [];
      this.$http
        .jsonp(getDiys + _hash)
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          // console.log('list-diy ', res);
          if(res.status==='200' && res.data && typeof res.data=='object') {
            this.diysList.push(res.data);
            if(this.diysList[0]) {
              let item = this.diysList[0];
              item.originalPrice = parseFloat(item.price||0).toFixed(2);
              item.price = parseFloat(item.amount||0).toFixed(2);
              item.num = 1;
              // item.num = 0;
              this.totalAmount = item.num * + item.price;
              this.selectedTicket = item;
            }
          }
        })
    },
    getTicketList() {
      let url = `${getTicket}?sid=${sid(this.global.year)}${this.qd ? "&share="+this.qd : ''}`;
      // share-优惠码 channel-优惠渠道 discount-折扣
      this.$http
        .jsonp(url)
        .then((res) => {
          // console.log('list ', res);
          return res.json();
        })
        .then((res) => {
          // console.log('list ', res);
          if (res.errno === "0") {
            this.ticketList = res.data.map((item) => {
              return {
                ...item,
                num: 0,
              };
            });
          } else {
            console.log("获取门票失败");
          }
        });
    },
    add(ticket) {
      // console.log(ticket);
      this.ticketList.map((item) => {
        if (item.id !== ticket.id) {
          item.num = 0;
        }
      });
      this.diysList.map((item) => {
        if (item.id !== ticket.id) {
          item.num = 0;
        }
      });
      let minNumber = ticket.minNumber || ticket.minBuyNum;
      if (ticket.num + parseInt(minNumber) <= 99) {
        if (ticket.num >= minNumber) {
          ticket.num += 1;
        } else {
          ticket.num += parseInt(minNumber);
        }
        this.totalAmount = ticket.num * +ticket.price;
        this.selectedTicket = ticket;
      }
    },
    reduce(ticket) {
      let minNumber = ticket.minNumber || ticket.minBuyNum;
      if(this.isSpecial && ticket.num==1) {
        this.$toast('不能再减少啦');
        ticket.num = 1;
      } else {
        if (ticket.num - parseInt(minNumber) > 0) {
          if (ticket.num <= minNumber) {
            ticket.num -= parseInt(minNumber);
          } else {
            ticket.num -= 1;
          }
          this.totalAmount = ticket.num * +ticket.price;
        } else if (ticket.num - parseInt(minNumber) === 0) {
          ticket.num -= parseInt(minNumber);
          this.totalAmount = ticket.num * +ticket.price;
          this.selectedTicket = {};
        }
      }
    },
    generateOrder(e) {
      this.checkInfo().then(infodata => {
        console.log(infodata);
        console.log( $(e.target).attr('disable'), this.userInfo );
        if(!$(e.target).attr('disabled')) {
          if (!this.selectedTicket.num || this.selectedTicket.num <= 0) {
            alert("请选择您要购买的门票");
            return false;
          }
          $(e.target).attr({'disabled':'disabled'})
          const token = window.localStorage.getItem("token");
          let url = '';
          if(this.isSpecial) {
            if(this.checkUserInfo()) {
              $(e.target).removeAttr('disabled');
              return;
            }
            url = `${createOrderGoods}?goodsId=${this.selectedTicket.goodsId}&phone=${this.userInfo.phone}&amount=${this.selectedTicket.amount}&number=${this.selectedTicket.num}&hash=${this.qd}&createUserId=${this.selectedTicket.createdUser}&name=${encodeURI(this.userInfo.name)}&email=${encodeURI(this.userInfo.email)}&company=${encodeURI(this.userInfo.company)}&captcha=${this.userInfo.code}`;
          } else {
            url = `${createOrder}?token=${token}&number=${this.selectedTicket.num}&stid=${this.selectedTicket.id}&share=${this.qd}`; // &channel=${this.qd};
          }
          this.$http
            .jsonp(url)
            .then((res) => {
              return res.json();
            })
            .then((res) => {
              console.log(url, res, this.selectedTicket); // user_info
              if (res.status === "200") {
                let referUrl = encodeURIComponent(`${window.location.origin}/userCenter${this.qd ? '?qd='+this.qd : ''}`)
                window.location.href = `${payStands}?order=${res.data.orderNumber}&refer=${referUrl}`;
              } else if (res.status === "1008") {
                alert("请选择您要购买的门票");
              } else {
                console.log("生成订单失败");
              }
              setTimeout(() => {
                $(e.target).removeAttr('disabled');
              }, 200)
            });
        }
      })
    },


    // 检查个人信息
    checkInfo() {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem("token");
        if (token && token !== null) {
          let url = `${getUserInfo}?token=${token}`;
          this.$http
            .jsonp(url)
            .then((data) => {
              return data.json();
            })
            .then((res) => {
              console.log("order- checkinfo",res);
              if (res.status === "200") {
                // this.userInfo = res.data;
                for (const key in res.data) {
                  if (!res.data[key] || res.data[key]==="") {
                    if (window.confirm("购票需要您先完善个人信息")) {
                      let link = window.location.pathname + window.location.search;
                      localStorage.setItem('link_before_complete', link);
                      this.$router.push({
                        path: `/${this.global.year}/userCenter`,
                        // query: { active: "5" },
                        query: { selected: "2" },
                      });
                    } else {
                      this.$router.push({ path: '/2024sz/home' });
                    }
                    resolve(false);
                    return false;
                    // break;
                  }
                }
                resolve(true);
              } else if (res.code === "1001" || res.status === "1011") {
                this.$message({ message: "登录状态已过期，购票前请重新登录", type: "warning" });
                window.localStorage.removeItem("logins");
                window.localStorage.removeItem("token");
                let order = `/${this.global.year}/order`+`${this.global.hashParam.qd?'?qd='+this.global.hashParam.qd:''}`;
                window.localStorage.setItem("linkurl", order);
                setTimeout(() => {
                  this.$router.push({ path: `/${this.global.year}/login` });
                }, 2000);
                resolve(false);
                return false;
              } else {
                console.log("获取用户信息失败");
                resolve(false);
                return false;
              }
            });
        } else {
          this.$message({ message: "购票前请先登录", type: "warning" });
          window.localStorage.removeItem("logins");
          window.localStorage.removeItem("token");
          let order = `/${this.global.year}/order`+`${this.global.hashParam.qd?'?qd='+this.global.hashParam.qd:''}`;
          window.localStorage.setItem("linkurl", order);
          setTimeout(() => {
            this.$router.push({ path: `/${this.global.year}/login` });
          }, 2000);
          resolve(false);
          return false;
        }
      })
    },

    // 渠道购票检查信息填写
    checkUserInfo() {
      // console.log(this.userInfo.name, this.userInfo.company, this.userInfo.email, this.userInfo.phone, this.userInfo.code);
      let reg_ph1 = this.global.regs.phone[0];
      let reg_ph2 = this.global.regs.phone[1];
      let reg_e = this.global.regs.email;
      reg_ph1.lastIndex = 0;
      reg_ph2.lastIndex = 0;
      reg_e.lastIndex = 0;
      let flag = false;
      if(!this.userInfo.name || this.userInfo.name.length==0) {
        this.$toast('姓名不能为空');
        flag = true;
      } else if(!this.userInfo.company || this.userInfo.company.length==0) {
        this.$toast('公司不能为空');
        flag = true;
      } else if(!this.userInfo.email || this.userInfo.email.length==0) {
        this.$toast('邮箱不能为空');
        flag = true;
      } else if(!reg_e.test(this.userInfo.email)) {
        this.$toast('邮箱格式不正确');
        flag = true;
      } else if(!this.userInfo.phone || this.userInfo.phone.length==0) {
        this.$toast('手机号不能为空');
        flag = true;
      } else if(!this.userInfo.phone.match(reg_ph1) || !reg_ph2.test(this.userInfo.phone)) {
        this.$toast('手机号码格式不正确');
        flag = true;
      } else if(!this.userInfo.code || this.userInfo.code.length==0) {
        this.$toast('验证码不能为空');
        flag = true;
      }
      return flag;
    },
    sendCode(e) {
      let flag = false;
      let reg_ph1 = this.global.regs.phone[0];
      let reg_ph2 = this.global.regs.phone[1];
      reg_ph1.lastIndex = 0;
      reg_ph2.lastIndex = 0;
      if(!this.userInfo.phone || this.userInfo.phone.length==0) {
        this.$toast('手机号不能为空');
        flag = true;
      } else if(!this.userInfo.phone.match(reg_ph1) || !reg_ph2.test(this.userInfo.phone)) {
        this.$toast('手机号码格式不正确');
        flag = true;
      }
      let code_a = '.user_info .info_code a'; // e.target
      if(!flag && !$(code_a).attr('disabled')) {
        $(code_a).attr({'disabled':'disabled'});
        this.codeTips = '发送中···';
        this.$http.jsonp(sendCaptcha + this.userInfo.phone)
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            // console.log(res);
            if(res.code == 200) {
              this.codeTips = '发送验证码';
              this.$toast('发送成功');
              this.codeCountDown = 60;
              clearInterval();
              let timer = setInterval(() => {
                this.codeCountDown --;
                if(this.codeCountDown==0) {
                  clearInterval(timer);
                  $(code_a).removeAttr('disabled');
                }
              }, 1000);
            } else {
              this.codeTips = '发送验证码';
              this.$toast('发送失败，请重试');
              $(code_a).removeAttr('disabled');
            }
          })
          .catch(error => {
            console.error('Error:', error);
            $(code_a).removeAttr('disabled');
            this.codeTips = '发送验证码';
          })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .buy_ticket_mob {
    display: none;
  }
  .buy_ticket_pc {
    padding: 60px 25px 75px 25px;
    max-width: 1200px;
    margin: 0 auto;
    .ticket_title {
      h2 {
        font-size: 36px;
        font-weight: 500;
        color: #2b2b2b;
        margin: 0;
        text-align: left;
      }
      .title {
        white-space: nowrap;
        margin: 15px 0 0;
        font-size: 18px;
        font-weight: 500;
        color: #c1c1c1;
        text-align: left;
        &:before {
          content: "";
          width: 57px;
          display: block;
          padding-bottom: 15px;
          border-top: 3px solid var(--main-color);
        }
      }
    }
    .ticket_list {
      margin-top: 44px;
      padding: 10px 25px 0;
      table {
        width: 100%;
        td {
          text-align: center;
        }
        .title_row {
          td {
            height: 55px;
            line-height: 55px;
            background: var(--main-color);
            color: #fff;
            text-align: center;
            font-size: 18px;
            font-weight: 600;
          }
          .column1 {
            border-left: 1px solid var(--main-color);
          }
          .column4 {
            border-right: 1px solid var(--main-color);
          }
        }
        .content_row {
          &.active {
            background: #ddd;
            &.special {
              background: #dddddd80;
            }
          }
          td {
            border-bottom: 1px solid #ddd;
            height: 50px;
            line-height: 25px;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            color: #444;
          }
          .column1 {
            border-left: 1px solid #ddd;
          }
          .column4 {
            white-space: nowrap;
            border-right: 1px solid #ddd;
            .btn {
              width: 40px;
              height: 25px;
              line-height: 25px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              background: var(--main-color);
              color: #fff;
              font-size: 20px;
              font-weight: 700;
              border: none;
              outline: none;
              cursor: pointer;
            }
            .reduce {
              border-top-left-radius: 25px;
              border-bottom-left-radius: 25px;
            }
            .add {
              border-top-right-radius: 25px;
              border-bottom-right-radius: 25px;
            }
            .amount {
              border: none;
              font-size: 16px;
              margin: 0 10px;
              width: 20px;
              display: inline-block;
              text-align: center;
              vertical-align: bottom;
            }
          }
          .column2 {
            padding: 0 10px;
            white-space: nowrap;
            .old_price {
              color: #bbb;
              text-decoration: line-through;
            }
          }
        }
        .column1, .column4 {
          width: 20%;
        }
        .column2 {
          width: 28%;
        }
        .column3 {
          width: 32%;
        }
      }
    }
    .user_info {
      // margin-bottom: 10px;
      padding: 0;
      // background-color: #ddd;
      background-color: #dddddd80;
      // border-radius: 6px;
      // border: 1px solid var(--main-color);
      border-bottom: 1px solid #ddd;
      // &::before {
      //   content: '购票人信息';
      //   width: 100%;
      //   display: block;
      //   padding: 15px 20px 10px 20px;
      //   font-size: 16px;
      //   font-weight: 500;
      //   text-align: center;
      //   color: #fff;
      //   background-color: var(--main-color);
      // }
      .info_title {
        width: 100%;
        // height: 55px;
        // line-height: 55px;
        height: auto;
        min-height: 55px;
        line-height: 1px;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        color: #fff;
        background-color: var(--main-color);
        display: grid;
        justify-content: center;
        align-items: end;
        p {
          width: 100%;
          line-height: 1;
          padding-bottom: 6px;
          font-size: 12px;
          font-weight: 500;
          text-align: center;
          color: #fff;
          img {
            height: 12px;
          }
        }
      }
      .info_option {
        padding: 10px 20px 10px 0;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        position: relative;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
        // &:nth-of-type(1) {
        &.info_name {
          padding-top: 20px;
        }
        &:nth-last-of-type(1) {
          padding-bottom: 20px;
        }
        label, input, a {
          margin-top: 0;
          margin-bottom: 0;
          font-weight: 500;
        }
        label {
          width: 100%;
          padding-left: 20px;
          line-height: 32px;
          text-align: left;
          font-size: 16px;
          color: #444;
        }
        input {
          width: 280px;
          min-width: 280px;
          line-height: 24px;
          padding: 3px 10px;
          margin-left: 5px;
          font-size: 15px;
          color: #666;
          border: 1px solid var(--main-color);
          border-radius: 6px;
          outline: none;
          background-color: #fff;
          // background-color: #ffffff66;
          &:focus {
            // background-color: #fff;
            background-color: #ffffff66;
          }
        }
        a {
          position: absolute;
          display: block;
          width: 120px;
          height: 30px;
          line-height: 30px;
          font-size: 15px;
          color: #fff;
          right: 21px;
          top: 11px;
          z-index: 5;
          cursor: pointer;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          &::after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            background-color: var(--main-color);
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            opacity: 0.55;
          }
        }
        &.info_code {
          input {
            padding-right: 135px;
          }
        }
      }
    }
    .total {
      min-width: fit-content;
      text-align: right;
      p {
        font-size: 16px;
        font-weight: 500;
        color: #4c4c4c;
      }
      h2 {
        font-size: 30px;
        font-weight: 500;
        margin-top: 15px;
        margin-bottom: 15px;
      }
      .pay_wrapper {
        // margin-bottom: 10px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        span {
          font-size: 16px;
          color: #222;
          font-weight: 500;
          margin-right: 20px;
          // margin-top: 10px;
          // margin-bottom: 20px;
          display: inline-block;
        }
        .pay {
          border: none;
          outline: none;
          display: inline-block;
          width: 150px;
          height: 50px;
          line-height: 50px;
          background: var(--main-color);
          font-size: 20px;
          font-weight: 500;
          color: #fff;
          cursor: pointer;
        }
      }
    }
    .operation_list {
      margin-top: 55px;
      padding: 10px 25px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: flex-start;
      &.special {
        justify-content: space-between;
        &.flex-con {
          justify-content: flex-end;
          .user_info {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .info_title {
              display: flex;
              align-items: center;
              p {
                width: fit-content;
                margin-left: 15px;
                padding-top: 6px;
                padding-bottom: 0;
              }
            }
            .info_option {
              width: 50%;
              label {
                max-width: 100px;
                min-width: 84px;
                // white-space: nowrap;
              }
              input {
                width: 100%;
              }
              &.info_company {
                padding-top: 20px;
              }
              // &:nth-of-type(2n + 1) {
              &:nth-of-type(2n + 2) {
                border-left: 1px solid #ddd;
                border-right: none;
              }
              // &:nth-of-type(2n) {
              &:nth-of-type(2n + 1) {
                border-left: none;
                border-right: 1px solid #ddd;
              }
            }
            &::after {
              content: "";
              display: block;
              width: 50%;
              border-right: 1px solid #ddd;
            }
          }
          .total {
            margin-top: 50px;
          }
        }
      }
    }
  }
}
@media (max-width: 960px) {
  .buy_ticket_pc {
    .user_info {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      // position: relative;
      // padding-top: 50px;
      // &::before {
      //   position: absolute;
      //   top: 0;
      // }
      &::after {
        content: '';
        display: block;
        width: 50%;
        // border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
      }
      .info_option {
        width: 50%;
        border-left: none;
        border-right: none;
        &:nth-of-type(2n + 1 + 2) {
          border-left: 1px solid #ddd;
        }
        &:nth-of-type(2n + 2) {
          border-right: 1px solid #ddd;
        }
        label {
          width: 90px;
        }
        input {
          min-width: 220px;
        }
      }
    }
    .operation_list.special .total {
      width: 100%;
      margin-top: 50px;
    }
  }
}
@media (max-width: 767px) {
  .buy_ticket_pc {
    display: none;
  }
  .buy_ticket_mob {
    padding: 40px 6vw 0;
    text-align: left;
    .ticket_title {
      margin-bottom: 30px;
      h2 {
        font-size: 24px;
        font-weight: 800;
        color: #2b2b2b;
        margin: 0;
      }
      p {
        white-space: nowrap;
        margin: 15px 0 0;
        font-size: 12px;
        color: #c1c1c1;
        font-weight: 800;
        &:before {
          content: "";
          width: 57px;
          display: block;
          padding-bottom: 15px;
          border-top: 3px solid var(--main-color);
        }
      }
    }
    .ticket_item {
      margin-top: 15px;
      border-radius: 15px;
      border: 1.5px solid #e6e6e6;
      box-shadow: 0 0 3px #ebebeb;
      padding: 20px 12px 10px;
      &.active {
        border-color: var(--main-color);
      }
      .top {
        display: flex;
        justify-content: space-between;

        .left {
          display: flex;
          img {
            width: 16px;
          }
          p {
            font-size: 16px;
            margin-left: 6px;
            line-height: 16px;
            height: 16px;
          }
        }
        .right {
          display: flex;
          width: 105px;
          border: 1px solid #ccc;
          border-radius: 6px;
          display: flex;
          height: 35px;
          line-height: 30px;
          .btn {
            outline: none;
            width: 33.5%;
            text-align: center;
            color: #ccc;
            line-height: 30px;
            font-weight: 700;
            border: none;
            background: none;
          }
          .amount {
            outline: none;
            border: none;
            width: 33%;
            text-align: center;
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
            font-size: 16px;
            margin: 0;
          }
        }
      }
      .bottom {
        padding-left: 16px;
        .price {
          margin-top: 4px;
          display: flex;
          p {
            font-size: 20px;
            font-weight: 700;
          }
          h2 {
            font-size: 32px;
            font-weight: 700;
            margin-top: 3px;
            margin-bottom: 0;
            color: rgba(0, 0, 0, 0.85);
          }
          span {
            height: 30px;
            line-height: 30px;
            font-size: 16px;
          }
        }
        h4 {
          font-size: 14px;
          font-weight: 700;
          color: grey;
          text-decoration: line-through;
          margin-left: 2px;
        }
        h3 {
          margin-left: 2px;
          margin-top: 30px;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.85);
          font-weight: 500;
        }
      }
    }
    .user_info {
      margin-top: 30px;
      padding-bottom: 20px;
      // border-color: #ccc;
      .top {
        padding-bottom: 5px;
        justify-content: center;
        .left {
          h3 {
            width: 100%;
            font-size: 18px;
            text-align: center;
            p {
              margin-top: 5px;
              margin-bottom: 0;
              margin-left: 0;
              font-size: 13px;
              font-weight: 400;
              img {
                width: auto;
                height: 13px;
              }
            }
          }
        }
      }
      .info_option {
        margin-top: 15px;
        padding: 0 6px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        position: relative;
        label, input, a {
          margin-top: 0;
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 500;
          // color: #000;
        }
        label {
          width: 60px;
          min-width: 60px;
          line-height: 30px;
        }
        input {
          width: 100%;
          line-height: 24px;
          padding: 2px 6px;
          border-radius: 6px;
          border: 1px solid #ccc;
          outline: none;
          &:focus {
            background-color: #cccccc30;
          }
        }
        a {
          width: 105px;
          line-height: 28px;
          font-size: 13px;
          text-align: center;
          position: absolute;
          right: 6px;
          // top: 16px;
          background-color: #cccccc5d;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
        &.info_code {
          input {
            padding-right: 110px;
          }
        }
      }
    }
    .total {
      min-width: 0;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      height: 55px;
      background: #fff;
      border-top: 1.5px solid #e6e6e6;
      .total_price {
        font-size: 16px;
        span {
          font-size: 18px;
          font-weight: 700;
        }
      }
      .pay_btn {
        height: 45px;
        line-height: 45px;
        margin-top: 5px;
        background: var(--main-color);
        color: #fff;
        width: 90px;
        border: none;
        outline: none;
        margin-left: 15px;
        margin-right: 3px;
        font-weight: 700;
        font-size: 15px;
        text-align: center;
        // justify-content: center;
      }
    }
  }
}
</style>